<template>
  <!-- 下载记录 -->
  <div class="download loading">
    <template v-if="!isShow">
      <div class="row" v-for="(item, i) in downloadList" :key="i">
        <el-alert :title="'下载时间 '+ item.day" type="info" show-icon :closable="false">
        </el-alert>
        <article-list :honDocuList="setkey(item.subList)" :isIndex="false" :isIcon="true" />
      </div>
    </template>
    <placeholder :isShow="isShow" />
  </div>
</template>
<script>
import { getDownloadRecords } from '@/api/user.js'
export default {
  data () {
    return {
      downloadList: [],
      isShow: false
    }
  },
  methods: {
    // 还原驼峰
    setkey (arr) {
      return arr.map(item => {
        const keyArr = Object.keys(item).map(item => (item.split('_').map(item => (item.charAt(0).toUpperCase() + item.slice(1)))).join('')).map(item => (item.charAt(0).toLowerCase() + item.slice(1)))
        const valArr = Object.values(item)
        const obj = {}
        keyArr.forEach((item, i) => {
          obj[item] = valArr[i]
        })
        return obj
      })
    },
    // 获取用户下载文献列表
    async getDownloadRecords () {
      try {
        const res = await getDownloadRecords()
        this.isShow = res.data.length === 0
        this.downloadList = res.data
      } catch (error) {
        this.$message.error('获取用户下载文献列表失败')
      }
    }
  },
  created () {
    this.getDownloadRecords()
  }
}
</script>
<style lang="less" scoped>
.download {
  min-height: 630px;
  padding-top: 1px;
}
.el-alert {
  position: relative;
  background-color: #f5f7fb;
  padding: 0 10px;
  /deep/.el-alert__title {
    font-size: 14px;
  }
}
</style>
